import './style.scss';

const Footer = () => {
  return (
    <footer>
      <div className="container">
        <p>
          Created by <a href="https://github.com/nenadpejic" target="_blanc">Nenad Pejic</a>
        </p>
      </div>
    </footer>
  );
}

export default Footer;