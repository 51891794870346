import './style.scss';
import '../Project/style.scss';
import { useContext, useState } from 'react';
import { Header, Main, Footer, AdminProjectsItem } from '../../components';
import { ProjectsContext } from '../../contexts';
import { setProject } from '../../services/firestore';
import { getCreatedAt, getGitHubAllRepos } from '../../services/github';
import { formatDate, compare } from '../../services/util';
import marked from 'marked';

const Admin = () => {
  const defaultState = {
    name: '',
    title: '',
    createdAt: '',
    thumbnail: '',
    html: ''
  }
  const projectsContext = useContext(ProjectsContext);
  const { projects } = projectsContext;
  const [md, setMd] = useState('');
  const [gitHubAllRepos, setGitHubAllRepos] = useState([]);
  const [localProject, setLocalProject] = useState({ ...defaultState });

  const handleChange = (e) => {
    const dataKey = e.target.dataset.key;
    const value = e.target.value;
    setLocalProject({
      ...localProject,
      [dataKey]: value
    });
  }

  const handleGetCreatedAt = (e) => {
    e.preventDefault();
    const data = localProject.name;
    getCreatedAt(data)
      .then(json => {
        if (json) {
          const createdAt = new Date(json.created_at);
          setLocalProject({
            ...localProject,
            createdAt: createdAt
          });
        }
      })
      .catch(err => {
        console.log(err)
      });
  }

  const handleConvert = (e) => {
    e.preventDefault();
    const html = marked(md);
    setLocalProject({
      ...localProject,
      html: html
    });
  }

  const handleSetProject = (e) => {
    e.preventDefault();
    const name = localProject.name;
    const data = localProject;
    setProject(name, data)
      .then(() => {
        console.log('Project set.');
        e.target.reset();
        setLocalProject({ ...defaultState })
      })
      .catch(err => {
        console.log(err)
      })
  }

  const handleGetAllRepos = (e) => {
    e.preventDefault();
    getGitHubAllRepos()
      .then(repos => {
        repos.sort(compare);
        setGitHubAllRepos(repos);
      })
  }

  return (
    <>
      <Header />
      <Main className='admin'>
        <h1>Admin</h1>
        <div className="flex">
          <div className="col-t-3">
            <section className='admin-projects'>
              <h2>Projects</h2>
              <ul>
                {projects.map(elem => (
                  <AdminProjectsItem key={elem.name} data={elem} />
                ))}
              </ul>
            </section>
          </div>

          <div className="col-t-6">
            <section className='admin-project-set'>
              <h2>Set a project</h2>
              <form className="admin-project-set-form" onSubmit={handleSetProject}>
                {/* Name */}
                <label htmlFor="admin-project-set-form-name">Github Repo Name:</label>
                <input type="text" name="admin-project-set-form-name" id="admin-project-set-form-name" required
                  data-key='name' onChange={handleChange} value={localProject.name} />
                {/* Title */}
                <label htmlFor="admin-project-set-form-title">Title:</label>
                <input type="text" title="admin-project-set-form-title" id="admin-project-set-form-title" required
                  data-key='title' onChange={handleChange} value={localProject.title} />
                {/* Created At */}
                <label htmlFor="admin-project-set-form-created-at">Created At:</label>
                <div>
                  <input type="text" name="admin-project-set-form-created-at" id="admin-project-set-form-created-at" required
                    data-key='createdAt' onChange={handleChange} value={localProject.createdAt} />
                  {' ' + formatDate(localProject.createdAt)}
                </div>
                <button className='btn-main' onClick={handleGetCreatedAt}>Get Created At from GitHub</button>
                {/* Thumbnail */}
                <label htmlFor="admin-project-set-form-thumbnail">Thumbnail url:</label>
                <div>
                  <input type="text" name="admin-project-set-form-thumbnail" id="admin-project-set-form-thumbnail" required
                    data-key='thumbnail' onChange={handleChange} value={localProject.thumbnail} />
                </div>
                {/* Markup */}
                <label htmlFor="admin-project-set-form-markup">Markup:</label>
                <textarea name="admin-project-set-form-markup" id="admin-project-set-form-markup" cols="30" rows="10" required
                  data-key='markup' onChange={e => setMd(e.target.value)} />
                <button className='btn-main' onClick={handleConvert}>Convert to HTML</button>
                {/* HTML */}
                <label htmlFor="admin-project-set-form-html">HTML:</label>
                <textarea name="admin-project-set-form-html" id="admin-project-set-form-html" cols="30" rows="10" required
                  data-key='html' onChange={handleChange} value={localProject.html} />
                <button className='btn-main' type='submit'>Set Project</button>
              </form>
            </section>
          </div>

          <div className="col-t-3">
            <section className='admin-project-repos'>
              <h2>Github repos</h2>
              {gitHubAllRepos.length
                ? <ul>
                  {gitHubAllRepos.map(elem => (
                    <li key={elem.name + '-repos'}>
                      <a href={"https://github.com/nenadpejic/" + elem.name} target='_blanc'>
                        <p>{elem.name}</p>
                        <p>{formatDate(elem.created_at)}</p>
                      </a>
                    </li>
                  ))}
                </ul>
                : null}
              <button className='btn-main' onClick={handleGetAllRepos}>Get All Repos</button>
            </section>
          </div>
        </div>

        <section className='admin-project-preview'>
          <h2>Preview</h2>
          <div className='admin-project-preview-div' dangerouslySetInnerHTML={{ __html: localProject.html }} />
        </section>

      </Main>
      <Footer />
    </>
  );
}

export default Admin;