export const formatDate = (data) => {
  // data = ''
  if (data) {
    let date;
    if (typeof data === 'string') {
      date = new Date(data);
    } else if (data.seconds) {
      date = new Date(data.seconds * 1000);
    } else if (typeof data === 'object') {
      date = data;
    }
    if (date.toString() !== 'Invalid Date') {
      const day = date.getDate().toString().padStart(2, "0");;
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear();
      return `${day}. ${month}. ${year}`;
    }
    return 'Invalid Date';
  }
  return '';
}

export const compare = (a, b) => {
  if (a.created_at < b.created_at) {
    return 1;
  }
  if (a.created_at > b.created_at) {
    return -1;
  }
  return 0;
}
