import './style.scss';
import { formatDate } from '../../services/util';

const AdminProjectsItem = ({ data }) => {
  return (
    <li>
      <p>{data.name}</p>
      <p>{formatDate(data.createdAt)}</p>
    </li>
  );
}

export default AdminProjectsItem;