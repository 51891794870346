import './style.scss';
import { Header, Main, Footer } from '../../components';
import { Redirect, useParams } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { ProjectsContext } from '../../contexts';

const Project = () => {
  const [redirect, setRedirect] = useState(false);
  const param = useParams();
  const projectsContext = useContext(ProjectsContext);
  const { currProject, projects, setCurrProject, setCounter } = projectsContext;

  useEffect(() => {
    if (currProject) {
      if (param.slug !== currProject.name) {
        projects.forEach((elem, i) => {
          if (elem.name === param.slug) {
            setCurrProject(projects[i]);
            setCounter(i);
            setRedirect(false)
          } else {
            setRedirect(true);
          }
        })
      }
    }
  }, [currProject, param.slug, projects, setCurrProject, setCounter])

  return (
    <>
      {redirect
        ? <Redirect to='/err' />
        : <>
          <Header />
          <Main className='project'>
            {currProject && <>
              <div dangerouslySetInnerHTML={{ __html: currProject.html }} />
            </>}
          </Main>
          <Footer />
        </>}
    </>
  );
}

export default Project;