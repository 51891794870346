import { authentication } from './firebase';

export const signIn = (data) => {
  // data = {
  //   email: 'foo@bar.com',
  //   password: '123'
  // }
  return authentication.signInWithEmailAndPassword(data.email, data.password);
}
export const signOut = () => {
  return authentication.signOut();
}

export const authStateListener = (cb) => {
  return authentication.onAuthStateChanged(cb);
}
