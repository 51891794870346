import './style.scss';

const Main = ({ children, className }) => {
  return (
    <main className={className}>
      <div className="container">
        {children}
      </div>
    </main>
  );
}

export default Main;