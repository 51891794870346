import { useState } from "react";
import { Footer, Header, Main } from "../../components";
import { Github, LinkedIn } from "../../icons";
import "./style.scss";

const About = () => {
  const defaultMsg = {
    email: "",
    linkedin: "",
    github: "",
  };
  const [msg, setMsg] = useState(defaultMsg);

  const handleCopy = (str) => {
    let text;
    if (str === "linkedin") {
      text = "https://www.linkedin.com/in/nenadpejic/";
    } else if (str === "github") {
      text = "https://github.com/nenadpejic";
    }
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setMsg({
          ...defaultMsg,
          [str]: "Copied to clipboard!",
        });
        setTimeout(() => {
          setMsg(defaultMsg);
        }, 2000);
        console.log("Copied to clipboard!");
      })
      .catch(() => {
        console.log("Copy fail");
      });
  };

  return (
    <>
      <Header />
      <Main className="about">
        <section className="about-info">
          <div className="c1">
            <img
              className="avatar"
              src="https://avatars1.githubusercontent.com/u/50808282?v=4"
              alt="avatar"
            />
          </div>
          <div className="c2">
            <h1>
              Hello,
              <br />
              I'm Nenad
            </h1>
            <p>
              I'm a serbian web developer currently based in Serbia. Let me know
              if you have a project idea in mind and we can work on creating
              something amazing.
            </p>
          </div>
        </section>
        <section className="about-at-work">
          <div className="c1">
            <h2>AT WORK</h2>
          </div>
          <div className="c2">
            <p>
              I ask a lot of questions to get as much information as possible to
              come up with the best possible solution for the task at hand. I
              aim to write clean code that is maintainable and scalable.
            </p>
          </div>
        </section>
        <section className="about-in-life">
          <div className="c1">
            <h2>IN LIFE</h2>
          </div>
          <div className="c2">
            <p>
              I'm the guy that organizes our group of friends to go for a
              basketball game, swimming or running. PvP arenas are where I login
              and logout. Also I enjoy green tea :)
            </p>
          </div>
        </section>
        <section className="about-contact">
          <div className="c1">
            <h2>CONTACT</h2>
          </div>
          <div className="c2">
            <div>
              <a href="https://www.linkedin.com/in/nenadpejic" target="_blanc">
                <LinkedIn fill="#fff" />
              </a>
              <p onClick={() => handleCopy("linkedin")}>@nenadpejic</p>
              {msg.linkedin && <span className="msg">{msg.linkedin}</span>}
            </div>
            <div>
              <a href="https://github.com/nenadpejic" target="_blanc">
                <Github fill="#fff" />
              </a>
              <p onClick={() => handleCopy("github")}>@nenadpejic</p>
              {msg.github && <span className="msg">{msg.github}</span>}
            </div>
          </div>
        </section>
      </Main>
      <Footer />
    </>
  );
};

export default About;
