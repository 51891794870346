import { firestore } from './firebase';

export const getProjects = () => {
  return firestore.collection('projects').get();
};

export const getProjectsOS = (snapshot, err) => {
  return firestore.collection('projects').orderBy("createdAt", "desc").onSnapshot(snapshot, err);
};

export const setProject = (name, data) => {
  if (name && data) {
    return firestore.collection('projects').doc(name).set(data);
  } else {
    console.log("Error while setting project!");
  }
}
