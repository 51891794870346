export const getCreatedAt = (data) => {
  // data = 'alas-shop'
  const requestOptions = {
    method: 'GET',
    redirect: 'follow'
  };
  return fetch("https://api.github.com/repos/nenadpejic/" + data, requestOptions)
    .then(response => {
      if (!response.ok) {
        console.log('GitHub API response: ' + response.statusText)
      } else {
        return response.json();
      }
    })
}

export const getGitHubAllRepos = () => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow'
  };
  return fetch("https://api.github.com/users/nenadpejic/repos", requestOptions)
    .then(response => {
      return response.json();
    })
    .then(json => {
      if (json.message) {
        console.log(json.message);
      } else {
        const repos = [];
        json.forEach(repo => {
          repos.push(repo);
        })
        return repos;
      }
    })
}