import './style.scss';
import { Link } from 'react-router-dom';
import { Header, Main, Footer } from '../../components';

const Welcome = () => {
  return (
    <>
      <Header />
      <Main className='welcome'>
        <h1>Hi, I'm Nenad.</h1>
        <p>Front-end Developer.</p>
        <Link className='btn-main' to='/projects'>My Projects</Link>
      </Main>
      <Footer />
    </>
  );
}

export default Welcome;