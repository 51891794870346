import './style.scss';
import { useContext, useState } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { Main } from '../../components';
import { signIn } from '../../services/authentication';
import { UserContext } from '../../contexts';

const SignIn = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [err, setErr] = useState('');
  const user = useContext(UserContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      email: email,
      password: password
    };
    signIn(data)
      .then(() => { })
      .catch(() => {
        setErr("Hmm.. Seems like you are not Nenad. Sry can't let you in. Have a nice day :)")
      });
  }

  return (
    <>
      {user
        ? <Redirect to='/admin' />
        : <Main className='sign-in'>
          <h1>Sign In</h1>
          <form className='sign-in-form' onSubmit={handleSubmit}>
            <label htmlFor="sign-in-form-email">Email</label>
            <input type="email" name="sign-in-form-email" id="sign-in-form-email" placeholder='Email...' required
              value={email} onChange={(e) => setEmail(e.target.value)} />
            <label htmlFor="sign-in-form-password">Password</label>
            <input type="password" name="sign-in-form-password" id="sign-in-form-password" placeholder='Password...' required
              value={password} onChange={(e) => setPassword(e.target.value)} />
            {err && <p className='err'>{err}</p>}
            <button className='btn-main' type="submit">Sign In</button>
            <p className='lost'>Lost? Back to <Link to='/'>home</Link>.</p>
          </form>
        </Main>}
    </>
  );
}

export default SignIn;