import './style.scss';
import { Link, withRouter } from 'react-router-dom';
import { signOut } from '../../../services/authentication';
import { useContext } from 'react';
import { UserContext } from '../../../contexts';

const Nav = ({ location }) => {
  const user = useContext(UserContext);

  return (
    <nav>
      <ul>
        <li>
          <Link to='/projects'>PROJECTS</Link>
        </li>
        <li>
          <Link to='/about'>ABOUT</Link>
        </li>
        {user && <li><span onClick={signOut}>SIGN OUT</span></li>}
      </ul>
    </nav>
  );
}

export default withRouter(Nav);