import { createContext, useEffect, useState } from "react";
import { authStateListener } from '../services/authentication';

export const UserContext = createContext();

const UserContextProvider = ({ children, ...rest }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    authStateListener(curUser => {
      setUser(curUser);
      // console.log('User set!', curUser);
    });
  }, []);

  return (
    <UserContext.Provider {...rest} value={user}>
      {children}
    </UserContext.Provider>
  );
}

export default UserContextProvider;