import { createContext, useEffect, useState } from "react";
import { getProjectsOS } from '../services/firestore';

export const ProjectsContext = createContext();

const ProjectsContextProvider = ({ children, ...rest }) => {
  const [projects, setProjects] = useState([]);
  const [counter, setCounter] = useState(0);
  const [currProject, setCurrProject] = useState(null);
  const projectsContext = {
    projects,
    counter,
    setCounter,
    currProject,
    setCurrProject
  }

  useEffect(() => {
    getProjectsOS((snapshot) => {
      if (snapshot.empty) {
        console.log('Projects snapshot is empty!');
      } else {
        const docs = snapshot.docs;
        const newArr = [];
        docs.forEach(elem => {
          if (!elem.exists) {
            console.log('Projects element does not exist!');
          } else {
            const data = elem.data();
            newArr.push(data);
          }
        });
        setProjects(newArr);
        // console.log('Projects set!', newArr);
      }
    }, (err) => {
      console.log(err)
    })
  }, []);

  useEffect(() => {
    if (projects.length) {
      setCurrProject(projects[counter]);
      // console.log('Current Project set!', currProject);
    }
  }, [counter, projects])

  return (
    <ProjectsContext.Provider {...rest} value={projectsContext}>
      {children}
    </ProjectsContext.Provider>
  );
}

export default ProjectsContextProvider;