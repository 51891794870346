import { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { UserContext } from '../contexts';

const AdminRoute = ({ children, ...rest }) => {
  const user = useContext(UserContext);

  return (
    <Route {...rest}>
      {user
        ? children
        : <Redirect to='/sign-in' />}
    </Route>
  );
}

export default AdminRoute;