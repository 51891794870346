import './scss/colors.scss';
import './scss/fonts.scss';
import './scss/resets.scss';
import './scss/utils.scss';
import './scss/responsive.scss';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Welcome, Projects, About, Project, Admin, SignIn, PageNotFound } from './pages';
import { AdminRoute } from './routes';

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path='/' component={Welcome} />
        <Route exact path='/projects' component={Projects} />
        <Route exact path='/projects/:slug' component={Project} />
        <Route exact path='/about' component={About} />
        <Route exact path='/sign-in' component={SignIn} />
        <AdminRoute exact path='/admin'>
          <Admin />
        </AdminRoute>
        <Route exact path='*' component={PageNotFound} />
      </Switch>
    </Router>
  );
}

export default App;
