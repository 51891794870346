import './style.scss';
import { Header, Main, Footer } from '../../components';

const PageNotFound = () => {
  return (
    <>
      <Header />
      <Main className='page-not-found'>
        <h1>PageNotFound</h1>
      </Main>
      <Footer />
    </>
  );
}

export default PageNotFound;