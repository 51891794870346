import { useContext } from "react";
import { Link } from "react-router-dom";
import { Footer, Header, Main } from "../../components";
import { ProjectsContext } from "../../contexts";
import { NextArrow, PrevArrow } from "../../icons";
import Laptop from "../../images/laptop.png";
import { formatDate } from "../../services/util";
import "./style.scss";

const Projects = () => {
  const projectsContext = useContext(ProjectsContext);
  const { projects, counter, setCounter, currProject } = projectsContext;

  const handleCounter = (param) => {
    if (param === "+") {
      if (counter < projects.length - 1) {
        setCounter(counter + 1);
      } else {
        setCounter(0);
      }
    } else if (param === "-") {
      if (counter > 0) {
        setCounter(counter - 1);
      } else {
        setCounter(projects.length - 1);
      }
    }
  };

  return (
    <>
      <Header />
      <Main className="projects">
        {currProject && (
          <>
            <div className="projects-content">
              <div className="projects-content-text">
                <div className="info">
                  <h1>{currProject.title}</h1>
                  <p>{formatDate(currProject.createdAt)}</p>
                  <Link
                    className={`btn-main`}
                    to={"/projects/" + currProject.name}
                  >
                    View Project
                  </Link>
                </div>
                <div className="counter">
                  <div className="counter-current">{counter + 1}</div>
                  <div className="counter-progress">
                    <div
                      className="counter-progress-current"
                      style={{
                        width: (100 * (counter + 1)) / projects.length + "%",
                      }}
                    ></div>
                  </div>
                  <div className="counter-max">{projects.length}</div>
                </div>
              </div>
              <div className={`projects-content-img`}>
                <img className="laptop" src={Laptop} alt="laptop" />
                <div className="window">
                  <img
                    className={`thumbnail`}
                    src={currProject.thumbnail}
                    alt="thumbnail"
                  />
                </div>
              </div>
              <PrevArrow
                className="btn-prev"
                onClick={() => handleCounter("-")}
              />
              <NextArrow
                className="btn-next"
                onClick={() => handleCounter("+")}
              />
            </div>
          </>
        )}
      </Main>
      <Footer />
    </>
  );
};

export default Projects;
