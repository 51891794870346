import './style.scss';
import { Link } from 'react-router-dom';
import Nav from './Nav';

const Header = () => {
  return (
    <header>
      <div className="container">
        <div className='logo'>
          <Link to='/'>NENAD PEJIC</Link>
        </div>
        <Nav />
      </div>
    </header>
  );
}

export default Header;